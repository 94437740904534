<template>
  <div class="container container-top" v-if="notice_detail">
    <div class="pc">
      <div class="subtitle2 main padding-top-44">{{ notice_detail.title }}</div>
      <div class="sub3 padding-top-24 body4">
        {{ datesFormat(notice_detail.created_time, 'date_3') }}</div>
      <div class="lp-divider-sub5 padding-top-24"></div>
      <div class="body2" style="padding:12px 0 24px 0" v-if="notice_detail">
        <div class="lp-detail-title padding-bottom-32">{{ notice_detail.title }}</div>
        <div class="padding-bottom-40" v-html="notice_detail.content"></div>

        <!-- 이전글 -->
        <div class="body2 prev-next-wrapper flex-align unselect"
             :style="prevNextWrapper('prev')"
             @click="clickPrevNext('prev')"
             v-if="notice_detail.prev">
          <div class="flex-align">
            <img class="drop-down-arrow-icon svg-primary" src="/static/icon/fi_chevron-up.svg" style="width:20px;height:20px"/>
            <div class="primary margin-left-4" style="width:54px;margin-left:6px">이전글</div>
          </div>
          <div class="margin-left-48 flex-between" style="width:100%">
            <div class="main margin-right-20 notice-title" style="width:880px">{{ notice_detail.prev.title }}</div>
            <div class="sub3">{{ datesFormat(notice_detail.prev.created_time, 'date_3') }}</div>
          </div>
        </div>

        <!-- 다음글 -->
        <div class="body2 prev-next-wrapper flex-align unselect"
             :style="prevNextWrapper('next')"
             @click="clickPrevNext('next')"
             v-if="notice_detail.next">
          <div class="flex-align">
            <img class="drop-down-arrow-icon svg-primary" src="/static/icon/fi_chevron-down.svg" style="width:20px;height:20px"/>
            <div class="primary margin-left-4" style="width:54px;margin-left:6px">다음글</div>
          </div>
          <div class="margin-left-48 flex-between" style="width:100%">
            <div class="main margin-right-20 notice-title" style="width:880px">{{ notice_detail.next.title }}</div>
            <div class="sub3">{{ datesFormat(notice_detail.next.created_time, 'date_3') }}</div>
          </div>
        </div>

        <div class="flex-center margin-top-40">
          <button class="button is-gray body2-medium"
                  style="width:242px"
                  @click="clickList">목록보기</button>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="h7 main padding-top-24">{{ notice_detail.title }}</div>
      <div class="body6 sub3 padding-top-12">
        {{ datesFormat(notice_detail.created_time, 'date_3') }}</div>
      <div class="lp-divider-sub5 padding-top-12"></div>
      <div class="body4" style="padding:12px 0 24px 0">
        <div class="lp-detail-title padding-bottom-32">{{ notice_detail.title }}</div>
        <div class="padding-bottom-40" v-html="notice_detail.content"></div>

        <div class="lp-divider-sub5 padding-top-12"></div>

        <div class="flex-center margin-top-40">
          <button class="button is-gray body5-medium"
                  style="width:170px;height:36px"
                  @click="clickList">목록보기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "NoticeDetail",
    created() {
      this.getData();
    },
    data() {
      return {
        notice_detail: undefined
      }
    },
    methods: {
      prevNextWrapper(type) {
        let deco = {
          borderTop: '1px solid #EEEEEE',
          borderBottom: '1px solid #EEEEEE',
          padding: '24px 0'
        };
        if(this.notice_detail.prev && this.notice_detail.next && type==='next') {
          delete deco.borderTop;
        }
        return deco;
      },
      clickPrevNext(type) {
        if(type==='prev') {
          this.routerPush(`/notice/detail?id=${this.notice_detail.prev.id}`);
          this.notice_detail = undefined;
          this.getData();
        } else {
          this.routerPush(`/notice/detail?id=${this.notice_detail.next.id}`);
          this.notice_detail = undefined;
          this.getData();
        }
      },
      // 목록으로
      clickList() {
        this.$router.replace('/notice');
      },
      getData() {
        this.$axios.get(`public/board/notice/${this.$route.query.id}/prev_next`).then(res=>{
          this.notice_detail = res.data;
        })
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .notice-title
    text-overflow ellipsis
    overflow hidden
    white-space nowrap

</style>